import React from "react"
import tw from "twin.macro"
import { ImageCarouselButtonProps } from "./ImageCarousel.d"

const ImageCarouselButton: React.FC<ImageCarouselButtonProps> = ({
  direction,
  onClick,
  hidden,
}) => {
  return (
    <button
      hidden={hidden}
      onClick={onClick}
      css={[direction === "left" && tw`rotate-180`, tw`h-16`]}
      aria-label={direction === "left" ? 'previous slide' : 'next slide'}
    >
      <svg
        aria-hidden="true"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="11 9 75 66"
      >
        <defs>
          <filter
            x="-23.3%"
            y="-23.5%"
            width="146.7%"
            height="153%"
            filterUnits="objectBoundingBox"
            id="86dfw20iya"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="3.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0428936298 0"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <path
            d="M0 0h43c17.673 0 32 14.327 32 32v2c0 17.673-14.327 32-32 32H0V0z"
            id="i6ngriim4b"
          ></path>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="m44.023 35.764 5.112 5.686-4.493 6.481a.823.823 0 0 1-.642.319.82.82 0 0 1-.634-.31 1.073 1.073 0 0 1-.24-.63 1.105 1.105 0 0 1 .179-.669L46.332 43h-9.207a.823.823 0 0 1-.634-.31 1.08 1.08 0 0 1-.241-.69c0-.27.094-.514.241-.69a.823.823 0 0 1 .634-.31h9.207l-2.966-3.56a1.061 1.061 0 0 1-.233-.551 1.134 1.134 0 0 1 .09-.599c.287-.394.566-.52.8-.526z"
            fill="#FFF"
            stroke="#FFF"
            strokeWidth=".5"
          ></path>
          <g opacity=".136" transform="matrix(-1 0 0 1 86 9)">
            <use
              fill="#000"
              filter="url(#86dfw20iya)"
              xlinkHref="#i6ngriim4b"
            ></use>
            <use fill="#D8D8D8" xlinkHref="#i6ngriim4b"></use>
          </g>
        </g>
      </svg>
    </button>
  )
}

export default ImageCarouselButton
