import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { ImageCarouselSlideTextProps } from "./ImageCarousel.d"
import TranslateClient from "../../../clients/TranslateClient"
import { LanguageContext } from "../../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require ("../../../helpers")

const ImageCarouselSlideText: React.FC<ImageCarouselSlideTextProps> = ({ headline, description }) => {
    const { language } = useContext(LanguageContext)
    const [text, setText] = useState({ headline, description })
  
    useEffect(() => {
      if (language === "en") {
        setText({ headline, description })
      } else {
        ;(async () => {
          const textToTranslate = getStringValuesArray([headline, description])
          const translations = await TranslateClient.translate(
            textToTranslate,
            language
          )
          const translated = mapValuesToObject(text, translations)
          setText(translated)
        })()
      }
    }, [language, headline, description])

    return (
        <>
          <p css={[tw`text-white text-5xl`, { textShadow: "0px 2px 5px #000000CC" }]}>
            {text?.headline}
          </p>
          <p css={[tw`text-white line-clamp-2`, { textShadow: "0px 2px 5px #000000CC" }]}>
            {text?.description}
          </p>
        </>
    )
}

export default ImageCarouselSlideText
