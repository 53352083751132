import React, { useRef, useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import tw from "twin.macro"
import "swiper/css"
import { Autoplay } from "swiper"
import ImageCarouselButton from "./ImageCarouselButton"
import { ImageCarouselProps } from "./ImageCarousel.d"
import { Image } from "../../Image"
import ImageCarouselSlideText from "./ImageCarouselSlideText"

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  slides,
  css,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  let swiperRef = useRef(null)

  const backButtonHandler = () => {
    if (swiperRef) {
      swiperRef.current?.slidePrev()
    }
  }

  const nextButtonHandler = () => {
    if (swiperRef) {
      swiperRef.current?.slideNext()
    }
  }

  const goToSlideHandler = (index: number) => {
    if (swiperRef) {
      swiperRef.current?.slideTo(index)
    }
  }

  return (
    <div css={[tw`relative h-80`, css]}>
      {slides && (
        <>
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
          }}
          onSwiper={swiper => {
            swiperRef.current = swiper
          }}
          onActiveIndexChange={swiper => {
            setCurrentIndex(swiper.activeIndex)
          }}
        >
          {slides?.map((item, index) => {
            const src = item.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
            const publicURL = item.backgroundImage?.publicUrl

            return <SwiperSlide>
              <div css={[tw`w-full h-80 relative`]}>
                <div css={[tw`absolute top-0 right-0 left-0 bottom-0 z-20`, (item?.description || item?.headline) && tw`bg-[rgba(0,0,0,0.35)]`]} />
                <Image src={src} publicURL={publicURL} alt={item.backgroundImage?.altText} css={[tw`h-full w-full`]} key={index} role="presentation" />
              </div>
            </SwiperSlide>
          })}
        </Swiper>
        <div
          css={[
            tw`absolute top-0 left-0 w-full h-full flex justify-between items-center z-10`
          ]}
        >
          <div css={[tw`flex-none w-[66px]`]}>
            <ImageCarouselButton
              hidden={currentIndex === 0}
              onClick={backButtonHandler}
              direction="left"
            />
          </div>
          <div
            css={[
              tw`relative text-center z-10 flex flex-col gap-6 h-full w-full justify-center px-4 md:px-12`,
            ]}
          >
            <ImageCarouselSlideText headline={slides[currentIndex]?.headline} description={slides[currentIndex]?.description} />
            <div css={[tw`absolute bottom-0 left-0 w-full hidden sm:block`]}>
              <Indicator goToSlide={goToSlideHandler} total={slides.length} current={currentIndex} />
            </div>
          </div>
          <div css={[tw`flex-none w-[66px]`]}>
            <ImageCarouselButton
              hidden={currentIndex === slides.length - 1}
              onClick={nextButtonHandler}
              direction="right"
            />
          </div>
        </div>
        </>
      )}
    </div>
  )
}

export default ImageCarousel

const Indicator = ({ total, current, goToSlide }: { total: number; current: number, goToSlide: (index:number) => void  }) => {
  const totalArray = Array(total).fill("")

  return (
    <div css={[tw`p-5 flex justify-center gap-5`]}>
      {totalArray?.map((_, index) => (
        <button onClick={() => goToSlide(index)} key={index}>
          <Circle fill={index === current} />
        </button>
      ))}
    </div>
  )
}

const Circle = ({ fill }: { fill: boolean }) => {
  return (
    <svg
      aria-hidden
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        {fill && <ellipse fill="#9ED8D5" cx="5.052" cy="5" rx="5.052" ry="5" />}
        {!fill && (
          <ellipse stroke="#9ED8D5" cx="5.052" cy="5" rx="5.052" ry="5" />
        )}
      </g>
    </svg>
  )
}
