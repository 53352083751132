import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface Props {
  title: string
  content: string
}

const PageTitle: React.FC<Props> = ({ title, content }) => {
  const [text, setText] = useState({ title, content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`pt-20 px-8 md:px-0 text-center`]}>
      <h1
        css={[tw`font-extrabold text-3xl md:text-4xl`]}
        dangerouslySetInnerHTML={{ __html: text?.title }}
      />
      <p
        css={[tw`max-w-3xl mx-auto mt-6`]}
        dangerouslySetInnerHTML={{ __html: text?.content }}
      />
    </article>
  )
}

export default PageTitle
