import { graphql } from "gatsby"
import React from "react"
import { TemplateProps } from "../templates"
import { DiningOrShopOptionsTemplatePageData } from "../dineshop"
import PageTitle from "../../components/DineAndShop/PageTitle"
import tw from "twin.macro"
import DineShopCard from "../../components/DineAndShop/DineShopCard"
import ImageCarousel from "../../components/Carousel/ImageCarousel/ImageCarousel"
import { SEO } from "../../components/SEO"

const ShoppingOptions: React.FC<
  TemplateProps<DiningOrShopOptionsTemplatePageData>
> = ({ data, location }) => {
  const { title, content, template } = data.wpPage
  const { diningHeroCarouselSlides, restaurantsOrShops } =
    template.diningShopOptionsPage

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <ImageCarousel slides={diningHeroCarouselSlides} />
        <div
          css={[
            tw`bg-top`,
            {
              backgroundImage: "url('/airport-interior.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            },
          ]}
        >
          <PageTitle title={title} content={content} />
          <article
            css={[
              tw`flex flex-col sm:flex-row sm:flex-wrap max-w-8xl mx-auto justify-center gap-0 sm:gap-6 mt-12`,
            ]}
          >
            {restaurantsOrShops?.map((item, index) => (
              <DineShopCard
                {...item}
                nodeType={item.detailsLink?.nodeType}
                key={index}
              />
            ))}
          </article>
        </div>
      </section>
    </div>
  )
}

export default ShoppingOptions

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        ... on WpTemplate_DiningOrShopOptions {
          templateName
          diningShopOptionsPage {
            diningHeroCarouselSlides {
              headline
              description
              backgroundImage {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
            restaurantsOrShops {
              flagComingSoon
              flagTemporarilyClosed
              summary
              detailsLink {
                ... on WpShop {
                  nodeType
                  slug
                }
                ... on WpRestaurant {
                  nodeType
                  slug
                }
              }
              thumbnailImage {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
          }
        }
      }
    }
  }
`
