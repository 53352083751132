import React, { useState, useContext, useEffect } from "react"
import { getImage } from "gatsby-plugin-image"
import { Restaurant, Shop } from "../../templates/dineshop"
import { ImageSharp } from "../../templates/templates"
import tw from "twin.macro"
import { Link } from "gatsby"
import TranslateClient from "../../clients/TranslateClient"
import {
  getStringValuesArray,
  mapValuesToObject,
  getDineShopPath,
} from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"
interface Props<T extends Shop | Restaurant> {
  detailsLink?: T
  flagComingSoon?: boolean
  flagTemporarilyClosed?: boolean
  summary?: string
  thumbnailImage?: ImageSharp
  nodeType: string
}

const arrow = (
  <svg
    aria-hidden
    width="22"
    height="20"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.194.792c.34-.076.665.025.925.272l6.847 8.126-6.74 9.632a1.122 1.122 0 0 1-.869.428 1.12 1.12 0 0 1-.86-.419 1.477 1.477 0 0 1-.334-.872 1.524 1.524 0 0 1 .246-.923l4.75-5.661H1.946c-.334 0-.64-.157-.86-.419A1.487 1.487 0 0 1 .75 10c0-.374.13-.712.336-.956.22-.262.526-.419.86-.419H16.16l-4.663-5.544a1.465 1.465 0 0 1-.328-.79c-.03-.291.02-.593.151-.853.303-.399.599-.584.874-.646z"
      fill="#385885"
      stroke="#385885"
      strokeWidth=".5"
      fillRule="evenodd"
    />
  </svg>
)

const DineShopCard = <T extends Shop | Restaurant>(props: Props<T>) => {
  const link = getDineShopPath(
    props.detailsLink?.nodeType,
    props.detailsLink?.slug
  )

  const comingSoonText = "COMING SOON"
  const temporarilyClosedText = "TEMPORARILY CLOSED"
  const summary = props.summary
  const [text, setText] = useState({
    temporarilyClosedText,
    comingSoonText,
    summary,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ temporarilyClosedText, comingSoonText, summary })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([temporarilyClosedText, comingSoonText, summary])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <CardLink to={link} disabled={props.flagComingSoon}>
      <div
        css={[
          tw`relative text-center sm:w-[25rem] h-[13rem] gap-4 bg-[rgba(234, 234, 234, 0.5)] flex flex-col justify-start items-center border border-[#d8d8d8] p-8`,
        ]}
      >
        <div css={[tw``]}>
          <CardImage img={props.thumbnailImage} />
        </div>
        <p css={[tw``]} dangerouslySetInnerHTML={{ __html: text?.summary }} />
        {props.flagComingSoon && <Banner>{text?.comingSoonText}</Banner>}
        {props.flagTemporarilyClosed && (
          <Banner>{text?.temporarilyClosedText}</Banner>
        )}
        {props.flagComingSoon !== true &&
          props.flagTemporarilyClosed !== true && (
          <div
              css={[
                tw`absolute top-0 right-6 h-full flex items-center sm:hidden`,
              ]}
            >
            {arrow}
          </div>
        )}
      </div>
    </CardLink>
  )
}

export default DineShopCard

const CardImage: React.FC<{ img: ImageSharp }> = ({ img }) => {
  if (!img?.localFile) return <div css={[tw`h-20`]} />
  return (
    <Image
      alt={img?.altText}
      src={img?.localFile?.childImageSharp?.gatsbyImageData}
      publicURL={img?.localFile?.publicURL}
      css={[tw`h-20`]}
      imgStyle={{ objectFit: "contain" }}
      role="presentation"
    />
  )
}

interface CardLinkProps {
  to: string
  disabled?: boolean
}

const CardLink: React.FC<CardLinkProps> = ({ to, disabled, children }) => {
  if (disabled || !to) return <>{children}</>
  return (
    <Link to={to} css={tw`text-black hover:!no-underline`}>
      {children}
    </Link>
  )
}

const Banner: React.FC = ({ children }) => {
  return (
    <div
      css={[
        tw`absolute top-0 left-0 w-full h-full bg-[rgba(234, 234, 234, 0.7)] flex items-center`,
      ]}
    >
      <div css={[tw`bg-blue-250 text-white text-center w-full py-2`]}>
        {children}
      </div>
    </div>
  )
}
